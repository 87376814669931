$main: #34d9c3;
$secondary: #00303c;

$radius: 8px;
@mixin radius() {
  border-radius:8px;
}

.form-inline .input-group {
  display: flex;
align-items: flex-start;
.input-group-addon {
  padding: 0;
  border: 0;
}
 input {
   border-radius:0;
   height: 35px;
 }
 .btn {
  border:0;
  border-radius:0;
  height: 35px;
  }
}

.bold {
  font-weight: bold;
}

table.table.history-table {
  tbody {
    tr, td {
      vertical-align: middle;
    }
  }
  .button {
    margin-bottom: 0;
  }
}

.account-button {
  display: block;
  text-align: left;
  font-size: 16px;
  background: #f0f0f0;
  border:1px solid #d4d4d4;
  padding: 12px 10px 12px 50px;
  position: relative;
  i {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 40px;
    border-right:1px solid #d4d4d4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.search-field {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  height: 33px;
  border-color:#fff;
  input[type=text] {
    height: 30px;
    line-height: 30px;
  }

}
.search-button {
  height: 33px;
  width: 33px;
  .fa {
    line-height: 33px;
  }
}
.search-button, .search-box {
  @include radius();

}
.open-cart-popup, .button, .product-image, .mozaic-banner-entry, .footer-columns-entry, .login-box, .simple-field,
.blog-entry .image, .categories-border-wrapper, .ui-slider-handle, .size-selector .entry, .size-selector .entry::after, .main-category-container, .blog-sidebar .image img {
  @include radius();
}

.serach-box.popup {
  form {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  .search-field {
    order:1;
  }
  .search-drop-down {

  }
  .search-button {
    order:2;
  }
}

.header-functionality-entry .fa {
  color: #fff;
}



.tab-switcher {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

.steps-title, .account-title {
  margin-bottom: 8px;
  padding-left: 0px;
  padding-bottom: 6px;
  border-bottom: 1px solid #efefef;
}
.steps {
  margin: 5px 0 20px;
  width: 100%;
  display: flex;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100;White+3D */
// background: #ffffff; /* Old browsers */
// background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* FF3.6-15 */
// background: -webkit-linear-gradient(top, #ffffff 0%,#e5e5e5 100%); /* Chrome10-25,Safari5.1-6 */
// background: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
background: #e0e0e0;
overflow: hidden;
@include radius();

  .step {
    flex: 1 1 auto;
    padding: 12px 8px;
    text-align: center;
    position: relative;
    padding-left: 20px;


    a {
      color: #00303c;
    }
    &.completed {
      background: $secondary;
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    &.active {
      background: $secondary;
      color: #ffffff;
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        right: -22px;
        top: 0;
        height: 0;
        width: 0;
        width: 0;
height: 0;
border-style: solid;
border-width: 19px 0 19px 22px;
border-color: transparent transparent transparent #00303c;
      }
      a {
        color: #ffffff;
      }
    }
  }
}

.address-box, .my-address-item {
  margin-bottom: 0;
  // flex-grow: 0;
  @media(min-width:767px) {
    // flex-basis:33%;
  }
  p {
    line-height: 1.4;

  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .button {
    margin-top: 12px;
  }
  h5 {
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.my-address-item {
  flex-basis:33%;

}

.cart-total-table {
  tr { td {
    border-top:0 !important;
  } }
  strong {
    font-weight: 800;
    font-size: 24px;
  }
}

.cart-summary-box {
  border:0;
  padding-top: 0;
}

.block-header {
  h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:before,
    &:after {
      content: '';
      // border-top: 1px solid #ebebeb;
      margin: 0 20px 0 0;
      flex: 1 0 20px;
      display: block;
      height: 8px;
      background-image: url("/images/header-line.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }

    &:after {
      margin: 0 0 0 20px;
    }




    // span {
    //   background: #fff;
    //   display: inline-block;
    //   padding-left: 4px;
    //   padding-right: 4px;
    // }
  }
  .description.menu {
    margin-top: 25px;
    a {
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px;
      font-size: 20px;
      display: inline-block;
      min-width: 80px;;
    }
  }
}

.shipping-box {
  strong {
    font-weight: bold;
  }
  h4 {
    margin-bottom: 10px;
  }
}

.account {
  p {
    margin-bottom: 10px;
  }
  h4 {
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.shipping-carrier  {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  .row > div {
    justify-content: center;
    flex-direction: column;
  }
  h4 {
    margin-bottom: 6px;
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
}

.shop-grid.list-view {
  .grid-buttons {
    display: none;
  }
}

.row.payment-options {
  margin-left: 0;
  margin-right: 0;
  > div {
    padding-left: 0;
    padding-right: 0;
    border:1px solid #dbdbdb;
    border-radius:3px;
  }

}

.payment-item {
  display: flex;
  align-items: center;
  text-align: center;
  color: #303030;

  padding: 40px 30px;
  background: #f8f8f8;
  &-name {
    flex: 1 1 60%;
    padding: 0 15px 0 30px;
  }
  &-logo {
    flex: 1 1 20%;
  }
  h4 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1.2;
  }
  h5 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #9e9e9e;
  }
}

.header-middle {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo-wrapper {
    width: auto;
    flex: 0 1 auto;
    flex-basis:200px;
  }
  .middle-entry {
    width: auto;
    flex-grow: 1;
    max-width:400px;
  }
  .right-entries {
    width: auto;
    flex: 0 1 auto;
    flex-basis:200px;
  }
}

.submenu {
  // display: block !important;
  .menu-image-title {
    padding: 8px 12px;
    .image {
      background: #BEBEBB;
    }
  }
  .submenu-list-title {
    padding: 8px 12px;
    a {
      color: #fff;
      text-shadow:1px 1px 1px rgba(0,0,0,0.3);
      &:hover {
        color: #fff !important;
      }
    }
  }
  .product-column-entry {
    border-right: 0;
    .image {
      height: 100px;
      margin-bottom: 0;
      transition: all .2s ease;
      border-radius:8px;
    }
    &:nth-child(1) .menu-image-title { .image { background: #00ceaf; } &:hover { .image { background: darken(#00ceaf,10%);} } }
    &:nth-child(2) .menu-image-title { .image { background: #febc00; } &:hover { .image { background: darken(#febc00,10%);} } }
    &:nth-child(3) .menu-image-title { .image { background: #46c5e7; } &:hover { .image { background: darken(#46c5e7,10%);} } }
    &:nth-child(4) .menu-image-title { .image { background: #ee6100; } &:hover { .image { background: darken(#ee6100,10%);} } }
    &:nth-child(5) .menu-image-title { .image { background: #a2a2fd; } &:hover { .image { background: darken(#a2a2fd,10%);} } }
    &:nth-child(6) .menu-image-title { .image { background: #4a4a4a; } &:hover { .image { background: darken(#4a4a4a,10%);} } }
    .description {
      margin-left: 12px;
      margin-right: 12px;
      > ul > li > a {
        font-weight: 600;
      }
    }
    li {

      i {
        display: none;
      }

    }
    ul ul {
      margin-left: 12px;
      margin-top: 6px;
      li {
        i {
          display: block;
          left:-8px;
          top:6px;
        }
      }
    }
  }
}
.scrollbar-track {
  background: #bebebe !important;
}
.scrollbar-thumb {
  background: #00303c !important;
}
.filters-scrollbar {
  height: 200px;
  width: 100%;
  overflow-y: auto;
  .row {
    margin-left: 0;
    margin-right: 0;
    > div:first-child {
      padding-left: 0;
    }
    > div:last-child {
      padding-right: 0;
    }
  }
}

.header-wrapper.dark {
  header {
  background: #34d9c3;
  color: #fff;
  &:before {
    background: red;
    position: absolute;
    height: 1000px;
    width: 100000px;
    left: 50%;
    margin-left: -50000px;
    bottom: -2px;
    content: "";
    background: #34d9c3;
    }
  }
  .header-middle {
    .progress {
      &:after {
        border-color:#34d9c3;
      }
    }
  }
  .header-top-entry {
    color: #fff;
    padding: 10px 0 10px 0;
    b {
      color: #fff;
    }
  }
  .header-functionality-entry {
    color: #fff;
    border-right-color: #fff;
  }
  .nav-overflow > nav > ul > li > a {
    color: #fff;
    @media (min-width:767px) {
      line-height: 40px;
    }
  }
  .fixed-header {
    .nav-overflow {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .nav-overflow > nav > ul {
      display: -webkit-inline-flex;
      display: -ms-inline-flex;
      display: inline-flex;
      align-items: center;
    }
    .fixed-header-square-button {
      line-height: 30px !important;
      margin-top: 0;
    }
    .nav-overflow > nav > ul > li > a {
    // line-height: 70px;
    // line-height: unset;
    }
  }
  .header-top {
    border-bottom:0;
  }
  .header-top-entry {
    .title {
      border-left-color:#fff;
    }
  }
  nav > ul > li > .fa {
    color: #fff;top:-8px;
    left:2px;
  }
  .header-top-entry:first-child .title {
    border-left: 0;
  }
  .header-middle {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom:0;
  }
  .navigation {
    border-bottom: 0;
  }
  .header-middle .right-entries {
    // position: relative;
  // top: 17px;
  }
}

.open-cart-popup i {
  position: relative;
}
.header-middle .open-cart-popup span.header-cart-count {
  position: absolute;
  right: 0;
  top: 2px;
  transform: translate(50%,-50%);
  width: 16px;height: 16px;
  font-size: 9px;
  border-radius: 10px;
  display: block;
  background: #34d9c3;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mozaic-banners-wrapper + .mozaic-banners-wrapper {
  position: relative;
  top: -16px;
}



.login-box {
  .article-container {
    margin-bottom: 10px;
  }
}

.product-column-entry {
  padding-top: 0;
}

.block-header{padding: 1px 0 0 0;margin-bottom: 30px;}

.button.facebook {
background: #3b5998;
color: #fff;
border-color:#3b5998;
}
.button.google {
  background: #DD4B39;
  color: #fff;
  border-color:#dd4b39;
}
h1,h2,h3,h4,h5,h6 {
  &.line {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:before,
    &:after {
      content: '';
      border-top: 1px solid #ebebeb;
      margin: 0 20px 0 0;
      flex: 1 0 20px;
    }

    &:after {
      margin: 0 0 0 20px;
    }
  }
}

.mozaic-social {
  display: flex;
  flex-direction: column;

  align-content: center;
  justify-content: center;
  position: absolute;
  right: -28px;
  top: 0;
  height: 100%;
  padding-bottom: 16px;
  &-item {
    display: inline-block;
    width: 28px;
    height: 28px;
    border:1px solid #555555;
    color: #555555;
    border-radius:14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0;

  }
}

.menu-image-title {
  position: relative;
  .submenu-list-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;


  }
}

.submenu .product-column-entry .menu-image-title .submenu-list-title {
  margin-left: 0;
  margin-right: 0;
}

.vspace {
  padding-top: 30px;
  padding-bottom: 30px;
}

.login-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media(min-width:767px) {
  .login-column-1 {
    padding-right: 30px;
    border-right:1px solid #ebebeb;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .login-column-2 {
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media(min-width:767px) {
.row-flex, .row-flex > div[class*='col-'] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex:1 1 auto;
}
.row-flex::before, .row-flex::after {
  display: none;
}
}

.row-flex-wrap {
-webkit-flex-flow: row wrap;
  align-content: flex-start;
  flex:0;
}

.align-items-center {
  align-items: center;
}



.container-flex > div[class*='col-'] div,.row-flex > div[class*='col-'] > div {
width:100%;
}

.product-slide-entry {
  .button {
    padding-left: 20px;
    padding-right: 20px;
    display: block;
  }
}

.product-combination-singles {
  cursor: pointer;
  transition: all .2s ease !important;
  &:hover {
    background: #ebebeb;
  }
  &.active {
    background: $main;
    color: #fff;
    .text-muted {
      color: #fcfcfc;
    }
  }
}

/*moje na szybko */
.product-manufacturer,.product-stock,.product-delivery{
  padding: 8px 0;
      margin-bottom: 15px;
      font-size: 12px;
  }
  .product-insert{
  color:green;}

  input[type=checkbox], input[type=radio] {
      margin: 0 0 0;
      margin-top: 0px \9;
      line-height: normal;
  }
  .product-combination-single {
  text-align: center;

}
  .product-combination-header{
  margin-bottom:20px;text-align: center;
display: -webkit-flex;
display: -ms-flex;
display: flex;
align-items: center;
> div {
  flex-grow: 1;
}
}

  .product-combinations {
      padding-top: 10px;
  }

  .product-combination-singles {
      padding-left: 0px;



      border: 1px solid #adadad;
      border-radius: 3px;
      vertical-align: middle;
      /* margin-bottom: 10px; */
      padding: 0 15px;
      transition: border-color 1s;
      padding-top: 15px;
      padding-bottom: 10px;
      > .row {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      > div {
        flex-grow: 1;
      }
    }
      + .product-combination-singles {
        margin-top: -1px;
      }
      .price {
        line-height: 1em;
        letter-spacing:unset;
      }
      small {
        display: block;
        font-size: 11px;
        margin-top: 6px;
      }
  }
  // .product-combination-singles:hover {
  // border: 1px solid #999;
  //     width: 102%;
  //     position: relative;
  //     left: -5px;
  //     border-radius: 4px;
  //     background-color: #cccccc;
  // }
  // .product-combination-singles:active {

  //     background-color: red;
  // }
  .product-combination-numner {
      padding-top: 5px;
  }


  .cart_wrapper {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      position: relative;
      /* width: 80%; */
      background-color: #fafafa;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      text-align: center;
      padding: 20px 0;
      /* margin: calc(2em + 20px) auto 0; */
  }

  .product-recommended {
    .block-header {
        padding-top: 0;
    }
  }

  .rating-bar {
    .row-flex {
      align-items: center;
      margin-bottom: 4px;
    }
    .progress {
      margin-bottom: 0;
    }
    .progress-column {
      padding-left: 0;
      padding-right: 0;
    }
  }


  .user-rating {
    &-header {
      .user-rating-box {
        display: inline-block;
        .star {
          display: inline-block;
        }

      }
      .user-rating-title {
        font-size: 14px;
        color: #ca1515;
        line-height: 14px;
        font-weight: 500;
        display: inline-block;
        margin-left: 10px;
      }
    }
    &-meta {
      margin-top: 14px;
      margin-bottom: 14px;
      color: #808080;
      .meta-item {
        display: inline-block;
        + .meta-item {
          margin-left: 8px;
        }
      }
    }
  }

  .blog-landing-box .blog-entry .content {
    margin-left: 10px;
  }

  .breadcrumb-box span {

      display: inline-block;
      font-size: 13px;
      color: #696969;
      line-height: 16px;
      margin-right: 7px;
      padding: 7px 0;

  }

  .main-category-container {
    padding: 30px 30px 0 30px;
    border:1px solid #696969;
    .pagination {
      display: none;
    }
    h2 {
      font-size: 20px;
      margin-bottom: 20px;
      a {
        font-size: 14px;
        display: inline-block;
        margin-left: 20px;
      }
    }
  }

.home-category {
  .pagination {
    display: none;
  }
  .products-swiper .swiper-button-prev, .products-swiper .swiper-button-next {
    top:-65px;
    background: #fff;
  }
}

.header-middle {
   .fa-shopping-cart {
    font-size: 25px;
    // color: #34d9c3;
  }
  .open-cart-popup {
    background: #00303c;
    color: #fff;
    height: 45px;
    padding-right: 14px;
    display: -webkit-inline-flex;
    display: -ms-inline-flex;
    display: inline-flex;
    align-items: center;
    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: 4px;
    }
    b {
      color: #fff;
      margin-left: 8px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .progress{
    width: 24px;
    height: 24px;
    line-height: 45px;
    background: none;
    margin: 0 5px 0 auto;
    box-shadow: none;
    position: relative;
    + p {
      font-size: 12px;
    }
}
.progress:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
}
.progress > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress .progress-left{
    left: 0;
}
.progress .progress-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 2px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress .progress-left .progress-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}
.progress .progress-right{
    right: 0;
}
.progress .progress-right .progress-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 0.8s linear forwards;
}
.progress .progress-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    // background: #44484b;
    font-size: 24px;
    color: #fff;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}
.progress.blue .progress-bar{
    border-color: #00303c;
}
.progress.blue .progress-left .progress-bar{
    animation: loading-2 .5s linear forwards .8s;
}
.progress.yellow .progress-bar{
    border-color: #fdba04;
}
.progress.yellow .progress-left .progress-bar{
    animation: loading-3 1s linear forwards 1.8s;
}
.progress.pink .progress-bar{
    border-color: #ed687c;
}
.progress.pink .progress-left .progress-bar{
    animation: loading-4 0.4s linear forwards 1.8s;
}
.progress.green .progress-bar{
    border-color: #1abc9c;
}
.progress.green .progress-left .progress-bar{
    animation: loading-5 1.2s linear forwards 1.8s;
}
@media only screen and (max-width: 990px){
    .progress{ margin-bottom: 20px; }
}
}

@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes loading-2{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}
@keyframes loading-3{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
@keyframes loading-4{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}
@keyframes loading-5{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}


.progress-bar {
  background: #34d9c3;
}
  .fixed-header .nav-overflow > nav > ul > li > .fa {
    color: #fff;
  }

  .navigation {
    .submenu {
      li {
        position: relative;
        // padding-left: 15px;
        padding-left: 4px;
        i {
          position: absolute;
          left: 0;
          top: 4px;
        }
      }
    }
  }

  .products-swiper {
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      top: -40px;
      right: 22px;
      display: inline-block;
      width: 30px;
      height: 30px;
      border:1px solid rgba(0,0,0,0.3);
      border-radius:15px;
      font-size: 20px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i {
        font-size: 20px;
        position: relative;
        left: 1px;
      }
    }
    .swiper-button-prev {
      right: 60px;
      i {

        position: relative;
        left: -1px;
      }
    }
  }

  .article-dropdown {
    max-height:8em;
    overflow: hidden;
    position: relative;
    transition:all .2s ease;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      display: block;
      content: ' ';
      transition:all .2s ease;
      opacity: 1;

      background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    }
    &.in {
      max-height: 100em;
      &:after {
    opacity: 0;
      }
      + .article-dropdown-toggle {
        i {
        transform:rotate(180deg);
        }
      }
    }


  }
  .article-dropdown-toggle {
    display: block;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    i {
      display: inline-block;
      transition: all .2s ease;
      font-size: 24px;
    }
  }

    .d-flex {display: flex;}

    .flex-row            { flex-direction: row !important; }
    .flex-column         { flex-direction: column !important; }
    .flex-row-reverse    { flex-direction: row-reverse !important; }
    .flex-column-reverse { flex-direction: column-reverse !important; }

    .flex-wrap         { flex-wrap: wrap !important; }
    .flex-nowrap       { flex-wrap: nowrap !important; }
    .flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
    .flex-fill         { flex: 1 1 auto !important; }
    .flex-grow-0       { flex-grow: 0 !important; }
    .flex-grow-1       { flex-grow: 1 !important; }
    .flex-shrink-0     { flex-shrink: 0 !important; }
    .flex-shrink-1     { flex-shrink: 1 !important; }

    .justify-content-start   { justify-content: flex-start !important; }
    .justify-content-end     { justify-content: flex-end !important; }
    .justify-content-center  { justify-content: center !important; }
    .justify-content-between { justify-content: space-between !important; }
    .justify-content-around  { justify-content: space-around !important; }

    .align-items-start    { align-items: flex-start !important; }
    .align-items-end      { align-items: flex-end !important; }
    .align-items-center   { align-items: center !important; }
    .align-items-baseline { align-items: baseline !important; }
    .align-items-stretch  { align-items: stretch !important; }

    .align-content-start   { align-content: flex-start !important; }
    .align-content-end     { align-content: flex-end !important; }
    .align-content-center  { align-content: center !important; }
    .align-content-between { align-content: space-between !important; }
    .align-content-around  { align-content: space-around !important; }
    .align-content-stretch { align-content: stretch !important; }

    .align-self-auto     { align-self: auto !important; }
    .align-self-start    { align-self: flex-start !important; }
    .align-self-end      { align-self: flex-end !important; }
    .align-self-center   { align-self: center !important; }
    .align-self-baseline { align-self: baseline !important; }
    .align-self-stretch  { align-self: stretch !important; }



.menu-button {
  color: #fff;
  top: 7px;
  z-index: 950;
}
.header-count {
  right: 8px;
top: 12px;
}
@media(max-width:1200px) {
  .navigation {
    background: $secondary;
  }
  .open-search-popup {
    display: none !important;
  }
  nav>ul>li, .navigation-header, .navigation-footer .socials-box ,.navigation-footer .socials-box a {
    border-color:rgba(255,255,255,0.2);
  }
.header-wrapper.dark nav > ul > li > .fa {
    left:auto;
    right:0;
    top:1px;

  }
  .menu-image-title .submenu-list-title {
    position: static;
    justify-content: flex-start;
  }
  .submenu {
    background: $secondary;
    border:0;
    .menu-image-title {
      padding: 0;
      a {
        padding: 10px;
      }
    }
  }
  .submenu .product-column-entry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .submenu-list-title .toggle-list-button {

  }
  .submenu .list-type-1 a {
    color: #fff;
    font-size: 12px;
  }
  .submenu .list-type-1 li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media(max-width:767px) {
  .home-category .products-swiper .swiper-button-prev, .home-category .products-swiper .swiper-button-next {
    top: -40px;
  }
  .input-group-addon, .input-group-btn {
    width: auto;
  }
  .vspace {
    padding-top: 15px;
  }
  .product-manufacturer, .product-stock, .product-delivery {
    margin-bottom: 0px;
  }
  .product-combination-numner {
    margin-bottom: 8px;
  }
  .detail-info-entry {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-middle .open-cart-popup {
    margin-right: 36px;
  }
  .header-top .right-entries {
    display: none;
  }
  .header-wrapper.dark .header-middle {
    padding-top: 8px;
    padding-bottom: 4px;
  }
  .menu-button {
  top: 18px;
  z-index: 550;


  }
   .breadcrumb-box {
    margin-bottom: 15px;
   }
  .block-header {
  margin-bottom: 5px;
  }
  .rating-bars {
    margin-bottom: 15px;
  }
  .product-reviews > .row {
    margin-bottom: 15px;
  }
  .block-header h3.title {
    font-size: 24px;
    text-align: left;
    margin-bottom: 5px;
    &:before {
      display: none;
    }
    &:after {
      z-index: 0;
    }
  }
   .banner-column, .mozaic-banners-wrapper.type-2 .banner-column {
    margin-bottom: 0px;
  }
.mozaic-banner-entry {
    margin-bottom: 10px !important;
  }
  .mozaic-banners-wrapper + .mozaic-banners-wrapper {
    top: 0;
  }
  .mozaic-banner-content .title {
    font-size: 24px;
    text-align: left;
  }
  .mozaic-banner-entry.type-2 .mozaic-banner-content .title, .mozaic-banner-entry.type-3 .mozaic-banner-content .title {
    font-size: 24px;
  }
  .mozaic-social {
    position: static;
    flex-direction: row;
    .mozaic-social-item {
      margin-right: 4px;
      margin-left: 4px;
    }
  }
  .my-address-item {
    flex-basis:100%;
  }
  .main-category-container {
    .swiper-button-prev, .swiper-button-next {
      top:-50px;
    }
  }
  .main-category-container h2 a {
    display: block;
    margin-left: 0;
  }
}

@media (max-width:560px) {
  .my-address-item {
    flex-basis:100%;
  }
  .steps {
    flex-wrap: wrap;
    .step {
      width: 100%;
      flex-basis:100%;
    }
  }
  .header-middle .open-cart-popup {
    padding-right: 14px;

    i {
      position: relative;
      top: 4px;
     }
  }
  .header-middle .open-cart-popup > b {
    display: none;
  }
}
